<template>
    <CToaster :autohide="interval">
        <template v-for="(notification) of notifications">
            <CToast :key="notification.id"
                    :show="true"
                    header="Tập đoàn Hoàng Minh Trung">
                {{ notification.text ? notification.text : 'Default text' }}
            </CToast>
        </template>
    </CToaster>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'FixedToast',
        computed: {
            ...mapState('toast', ['notifications', 'interval'])
        },  
    }
</script>
